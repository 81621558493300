import React, { useEffect, useRef, useContext } from "react"
import { navigate } from '@reach/router'
import moment from 'moment'
import Loader from 'react-loader-spinner'
import ReactMarkdown from 'react-markdown'
import Link from '../components/Link'

import Helmet from "react-helmet"

import Layout from '../components/layout'
import Section, { TextSection, ContainerSection, DoubleSection, BackgroundSection, WelcomeSection, Button, HalfTextSection } from '../components/Section'
import ImageButton from '../components/ImageButton'
import Header from "../components/Header"
import Modal from "../components/Modal"
import pic02 from '../assets/images/pic02.jpg'
import FridayBanner from '../assets/images/headers/easter-poster.png'

import Logo from '../assets/images/logo-large.png'
// import MissionaryImage from '../assets/images/home/missionary.jpg'
import MissionaryImage from '../assets/images/general/2.jpg'

import ChurchServiceImage from '../assets/images/home/service.jpg'
import GivingImage from '../assets/images/giving/1.jpg'
import GreetingImage from '../assets/images/home/praise.jpg'

import EventsImage from '../assets/images/home/events-white-light.jpg'
import WhatWeBelieve from  '../assets/images/home/what-we-believe.jpg'



import AlertImage from '../assets/images/alert.png'
import { getEvents, getFeaturedEvents, getTestimonies, getSundayBanner } from "../lib/API"
import { useData, useDataOrNull, useLocalData } from "../lib/hooks"
import EmbedMap from "../components/EmbedMap"
import MainModalButtons from "../components/MainModalButtons"
import BannerSection from "../components/BannerSection"
import Slideshow from "../components/Slideshow"
import BannerContext from "../lib/context"
import Footer from "../components/Footer"
import { slug } from "../lib/utils"
import Video from "../components/Video"


const EventsBanner = () => {

    const events = useData( getFeaturedEvents )
    const removeDuplicates = ( arr, entry ) => {
        const dupArr = []
        const finalArr = []
        arr.forEach( item => {
            if( item[entry] ){
                if( !dupArr.includes( item[entry] ) ){
                    dupArr.push( item[entry] )
                    finalArr.push( item )
                }
            } else {
                throw 'Entry is not a key in the object'
            }
        } )
        return finalArr
    }

    const filteredEvents = removeDuplicates( events, 'title' )

    return (
        <section id="events-banner" className="main style1 special large"> 
                <Slideshow images={[ EventsImage ]} tint={0} opacity={.3}/>         
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Events</h2>
                        </header>
                    </div>
                    {filteredEvents.map( event => 
                        <div key={event.id} data-aos='fade-up' data-aos-duration='800' className="col-3 event" onClick={ () => navigate( `/whats-on/${slug( event.title )}`, { state: { title: event.title, extra: { ...event } } } )}>
                            {/* <h2>29 APR</h2> */}
                            <span className="image fit"><img src={event.image || pic02} alt="" /></span>
                            
                            <h3>{event.title}</h3>
                            <span style={{fontSize: '0.75em'}}>
                                <p>{event.description && event.description.substring( 0, 100 )}</p>
                            </span>
                        </div>
                    )}
                    <div className="col-12 actions" style={{ zIndex: 2 }}><Link to="/whats-on/#events" className="button transparent-button" style={{ color: 'gray' }}>All Events</Link></div>
                </div>
            </section>
    )
}


const Homepage = () => {
    // const headerData = useData( getHomeHeader )
    // const givingButtonRef = useRef()
    const testimonies = useDataOrNull( getTestimonies )
    const sundayBanner = useData( getSundayBanner ) || { image: null }



    // const [ headerData, setHeaderData ] = useState( [ ] )
    const siteTitle = "Blackheath and Charlton Baptist Church"

    const pageName = 'homepage-banners'
    const pageData = useContext( BannerContext )[ pageName ]


    // const pageData = useLocalData( getStaticPageData, pageName, pageName )
    const { header, banners, information_banners } = pageData || { header: null, banners: null, information_banners: null }
    const headerImages = header ? header.images : []

    // const headerTitle = ( <span className="major">Jesus said <br /></span> )
    const headerTitle = header ? header.title : ""
    // const headerText = ( <span>I am the vine, you are the branches. <br />
    // If you remain in me and I in you, you will bear much fruit, <br />
    // Apart from me you can do nothing.</span> )
    // const headerText = `I am the vine, you are the branches.
    //     If you remain in me and I in you, you will bear much fruit,
    //     Apart from me you can do nothing`
    const headerText = header && header.text
    // const headerIcon = 
    // <span className="icon major"><i className="fas fa-home"></i></span>
    // const headerIcon = <img className='homepage-header-logo' src={Logo} />



    useEffect( () => {
        
    }, [] )


    return (
        
        <div id='home-page'>
            <Header className='homepage-header' images={headerImages} headerTitle={headerTitle} headerText={headerText} tint={0.1} />
            <Helmet title={siteTitle} />
            
            <WelcomeSection 
                id="welcome"
                style="style2 welcome-banner"
                title="Welcome to Blackheath and Charlton Baptist Church (BCB Church)"
                description={`Blackheath and Charlton Baptist Church is a Christ-centred multicultural community that is empowered by God our Father, Christ our Lord and the Holy Spirit to live, love, and share the truth of Christ's life by encouraging, equipping, nurturing and transforming the lives of those we encounter - both in our local community and further afield`}
                buttons={[ { url: "/about-us/#who-we-are", special: false, text: "More about us", id: 'about-button' } ]}
                tint={0}
                opacity={0.1}
                background={[EventsImage]}
            ><div style={{justifyContent: 'center', alignItems: 'center', }}><iframe 
                style={{ minWidth: "100%", minHeight: "500px", padding: "1em", borderRadius: '1.8em'}} 
                src="https://www.youtube.com/embed/z_e6ijWmMbg" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
            /></div> 
            </WelcomeSection> 

            <MainModalButtons />
            
            {/* <div data-aos='fade-in' data-aos-duration='1200' className='major-event-banner'>
                <Link to="https://www.youtube.com/channel/UCo1-ajfKW62orxAK4TPkQ3Q/live"><img className='event-banner-img fit' src={sundayBanner.image} /></Link>
            </div> */}


            {/* <div data-aos='fade-in' data-aos-duration='1200' className='major-event-banner'>
                <img className='event-banner-img christmas fit full' src={ChristmasBanner} />
                <Link to="/whats-on/21-days-prayer-%26-fasting" className="button transparent-button" style={{ }}>More Info</Link> 
            </div> */}

            {/* <div className="grid-wrapper" style={{width: '100%', gridGap: '0 0'}}>
                <div data-aos='fade-in' data-aos-duration='1200'>
                    <Link to="https://www.youtube.com/channel/UCo1-ajfKW62orxAK4TPkQ3Q/live">
                        <HalfTextSection className='sunday-banner' breakpoint={1024} extraCSS={{backgroundImage: `url(${sundayBanner.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', minWidth: '50vw', minHeight: '50vh'}} />
                    </Link>
                </div>
                <div data-aos='fade-in' data-aos-duration='1200'>
                    <Link to="https://www.youtube.com/channel/UCo1-ajfKW62orxAK4TPkQ3Q/live">
                        <HalfTextSection className='sunday-banner' breakpoint={1024} extraCSS={{backgroundImage: `url(${FridayBanner})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', minWidth: '50vw',  minHeight: '50vh'}} />
                    </Link>
                </div>
            </div> */}
            <BannerSection banners={information_banners} />
            <EventsBanner />
            

        
            <BackgroundSection 
                id="sermons"
                title="Services"
                className="style2"
                description="Find all information regarding our services and operations here and explore our wide selection of audio and video sermons.  "
                buttons={[ { url: "/media/#video-highlight", text: "Sermons", special: false, style: 'transparent-button' } ]}
                // background={[ ChurchServiceImage ]}
                // roundedImg
                background={[ ChurchServiceImage ]}
                tint={0.4}
                blur={0.125}
                alt
            />

            <BackgroundSection
                id="giving"
                className="style2"
                title="Donations"
                description={`“Each of you must give as you have made up your mind, not reluctantly or under compulsion, for God loves a cheerful giver.” 
                    2 Corinthians 9:7 NSRV `}
                buttons={[ { url: "/giving", text: "Donate Now", special: false, style: 'transparent-button' } ]}
                // image={GivingImage}
                background={[ GivingImage ]}
                tint={0.5}  
             />
            


            <BackgroundSection 
                id="missionary-work"
                className="style2"
                title="Missionary Work"
                description={`For even the Son of Man did not come to be served, but to serve and to give His life as a ransom for many. 
                    Mark 10:45 NIV`}
                buttons={[ { url: '/missions/', text: "See More", style: 'transparent-button' } ]}
                background={[ MissionaryImage ]}
                tint={0.5}

            />


            <section id="testimonies" className="main style1 special"> 
                <Slideshow images={[ EventsImage ]} tint={0} opacity={0.05}/>         

                <div className="grid-wrapper">
                    <div className='col-12'><header className="major"><h2>Testimonies</h2></header></div>
                    {!testimonies ?
                        <div className='col-12'>
                            <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            /> 
                        </div>
                        :
                        testimonies.slice( 0, 3 ).map( testimony =>
                        <div data-aos="zoom-in" data-aos-duration="900" className="col-4 testimony" onClick={() => navigate( `/testimonies/${slug( testimony.title )}`, { state: testimony } )}>
                            <h4><em><b>{moment( testimony.date ).format( 'MMM YYYY' ).toString()}</b></em></h4>
                            <div className='testimony-content'>
                                <ReactMarkdown children={testimony && testimony.content.substring( 0, 200 )+"..."} />
                            </div>
                        </div>
            
                        ) }
                        {testimonies && 
                            <div className="col-12">
                                <ul className="actions uniform">
                                    <li><Link to="/testimonies" className="button">More Testimonies</Link></li>
                                </ul>
                            </div>
                        }
                </div>
            </section>

            <BannerSection banners={banners} />

            {/* <section data-aos="fade-up" id="contact" className="main style1">
                <div className="grid-wrapper">
                    <div className="col-6">
                        <header className="major">
                            <h2>Contact Us</h2>
                        </header>
                        <p>A paragraph describing the services and operations</p>
                        <ul className="actions uniform">
                            <li><Link to="/contact" className="button">Reach Us</Link></li>
                        </ul>
                    </div>
                    <div data-aos="zoom-in" className="col-6">
                        <EmbedMap />
                    </div>
                   
                   
                </div>
            </section> */}
            {/* <Footer /> */}
        </div>
    )
}

export default Homepage